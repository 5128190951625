import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { ErrorBoundary } from '@utils/ErrorBoundary'
import { trpc, trpcClient, queryClient } from '@api/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import Marketplace from './pages/Marketplace'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
})

function MarketplacePage({
  shouldDisplayHeader = true,
}: {
  shouldDisplayHeader?: boolean
}) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Marketplace shouldDisplayHeader={shouldDisplayHeader} />
        </BrowserRouter>
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export const { bootstrap, mount, unmount } = lifecycles

export { MarketplacePage }
