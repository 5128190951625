// @filename: client.ts
import { QueryClient } from '@tanstack/react-query'
import { createTRPCReact, httpBatchLink } from '@trpc/react-query'
import { envService } from './EnvService'
import { getAccessToken } from '@flash-tecnologia/hros-web-utility'
import type { AppRouter } from 'bff'

export const trpc = createTRPCReact<AppRouter>()

export const queryClient = new QueryClient()
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      async headers() {
        const token = await getAccessToken()
        return {
          authorization: token,
          'x-flash-auth': `Bearer ${token}`,
        }
      },
      url: envService.BFF_URL,
    }),
  ],
})
